<template>
    <div class="product-index">
        <md-tabs @change="tabChange">
            <md-tab md-label="Products" :md-active="tabIndex==0">
                <product-list v-if="tabIndex==0"></product-list>
            </md-tab>
            <md-tab md-label="Variants" :md-active="false" :md-disabled="true">
                <!-- <product-item-list v-if="tabIndex==1"></product-item-list> -->
            </md-tab>
            <md-tab md-label="Add Product" :md-disabled="user.role=='staff'&&!hasPermission('edit_product')" :md-active="tabIndex==2">
                <product-edit v-if="tabIndex==2"></product-edit>
            </md-tab>
            <md-tab md-label="Edit Product" v-if="tabIndex==3" :md-active="tabIndex==3">
                <product-edit :id="id" v-if="tabIndex==3"></product-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ProductList from "@/components/Product/ProductList";
import ProductItemList from "@/components/Product/ProductItemList";
import ProductEdit from "@/components/Product/ProductEdit";
export default {
    props: ["id"],
    components: {
        ProductList,
        ProductItemList,
        ProductEdit
    },
    data() {
        return {
            tabIndex: 0,
            tabChangeCount: 0
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole",
            hasPermission: "user/hasPermission"
        })
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                console.info("id", this.id);
                if (!this.id) {
                    this.tabIndex = 0;
                } else if (this.id == "items") {
                    this.tabIndex = 1;
                } else if (this.id == "new") {
                    this.tabIndex = 2;
                } else {
                    this.tabIndex = 3;
                }
            }
        }
    },
    methods: {
        tabChange(index) {
            if (this.tabChangeCount) {
                if (index == 0) {
                    this.$router.push("/admin/products");
                }
                if (index == 1) {
                    this.$router.push("/admin/products/items");
                }
                if (index == 2) {
                    this.$router.push(`/admin/products/new`);
                }
                if (index == 3) {
                    this.$router.push(`/admin/products/${this.id}`);
                }
            }
            this.tabChangeCount++;
        }
    }
};
</script>