
<template>
    <div class="product-item-list">
        <list :fs="itemFields" api="products" apiList="products/myVariants?type=Product" :addLink="addLink" editLink="/admin/products" :showAction="showAction" title="Products Items"></list>
    </div>
</template>

<script>
// removed
import { mapGetters } from "vuex";
import List from "@/components/List";
import { itemFields } from "@/config";
export default {
    data() {
        return {
            variants: []
        };
    },
    components: {
        List
    },
    computed: {
        ...mapGetters({
            categories: "category/categories",
            user: "user/user",
            hasRole: "user/hasRole",
            hasPermission: "user/hasPermission"
        }),
        itemFields() {
            if (!this.hasRole("manager")) {
                var approvedField = itemFields.find(field => field.data == "order.approved");
                approvedField && (approvedField.action = false);
            }
            return itemFields;
        },
        addLink() {
            return this.user.role == "vendor" ? "/admin/products/new" : "";
        },
        showAction() {
            return !(this.user.role == "staff" && !this.hasPermission("edit_product"));
        }
    },
    methods: {
        async loadVariants() {
            let result = await this.$store.dispatch("crud/get", {
                api: "products/myVariants"
            });
            result.forEach(p => {
                let category = this.categories.find(c => c._id == p.product.category);
                if (category) {
                    let ls = {
                        l1: "",
                        l2: "",
                        l3: ""
                    };
                    ls[`l${category.level}`] = category;
                    ls[`l${category.level - 1}`] = this.categories.find(c => category.parent && c._id == category.parent._id);
                    ls[`l${category.level - 2}`] = this.categories.find(c => ls[`l${category.level - 1}`] && ls[`l${category.level - 1}`].parent && c._id == ls[`l${category.level - 1}`].parent._id);
                    p.product.category = [ls[`l1`], ls[`l2`], ls[`l3`]]
                        .filter(a => a)
                        .map(a => a.name)
                        .join(" - ");
                }
            });
            this.variants = result;
        }
    },
    mounted() {
        // this.loadVariants();
    },
    async created() {
        await this.$store.dispatch("category/loadCategories");
    }
};
</script>